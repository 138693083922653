.responsive-button {
  display: none;
  outline: none;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin-right: 40px;

  &.active {
    transform: rotate(90deg);
  }
  @media (max-width: 768px) {
    display: inline-flex;
  }
  @media (max-width: 600px) {
    margin-right: 32px;
  }
}

.responsive-menu {
  position: absolute;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  left: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;

  @media (max-width: 425px) {
    padding: 20px;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &__list {
    display: flex;
    @media (max-width: 425px) {
      flex-direction: column;
    }
  }

  &__item {
    list-style: none;
    margin-right: 80px;

    @media (max-width: 425px) {
      margin-right: 0;
      margin-bottom: 10px;
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  &__link {
    line-height: 26px;
  }
}

.responsive-button,
.responsive-menu {
  transition: all 0.3s ease-in-out;
}
