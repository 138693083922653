@import "normalize-scss/sass/normalize/import-now.scss";
@import "icons.scss";
@import "slideout.scss";
@import "var.scss";

* {
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  @media (max-width: 425px) {
    font-size: 16px;
  }
}

a {
  color: $blue-color;
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
}

.container {
  width: 1200px;
  max-width: 100%;
  padding: 0 40px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: auto;
  }
  @media (max-width: 425px) {
    padding: 0 20px;
  }
}

.heading {
  font-size: 32px;
  font-weight: normal;
  line-height: 28px;
  letter-spacing: 0.4px;
  padding: 53px 0 24px;
  margin: 0;
  @media (max-width: 425px) {
    padding: 40px 0 16px;
    font-size: 28px;
  }
}
