.clients {
  &__item {
    display: flex;
    padding: 16px 0;
    border-bottom: 1px solid #dddddd;
    @media (min-width: 769px) {
      justify-content: space-between;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 32px 0;
    }

    @media (max-width: 600px) {
      padding: 24px 0;
    }

    @media (max-width: 425px) {
      padding: 16px 0;
    }

    &:first-child {
      padding-top: 10px;
    }
  }

  &__link {
    line-height: 26px;
    @media (min-width: 769px) {
      padding: 16px 0;
    }
    @media (max-width: 768px) {
      padding-bottom: 8px;
    }
  }
}

.clients-inner-list {
  width: 55%;
  // @media(max-width: 768px) {
  //   width: auto;
  // }
  &__item {
    font-weight: 300;
    line-height: 26px;
    padding: 16px 0;
    @media (min-width: 769px) {
      border-bottom: 1px solid #dddddd;
    }
    @media (max-width: 768px) {
      padding: 8px 0;
    }

    &:last-child {
      border-bottom: 0;
      @media (max-width: 768px) {
        padding-bottom: 0;
      }
    }
  }
}
