.about {
  &__text {
    font-weight: 300;
    line-height: 1.78;
    letter-spacing: 0.2px;
    text-align: left;
    color: #000000;
  }

  & a {
    color: $blue-color;
    border-bottom: 1px solid $blue-color;
    padding-bottom: 2px;
    white-space: nowrap;
  }
}
