.banner-company {
  background-image: url("../img/banner-bg-1920-px.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__wrapper {
    display: flex;
    align-items: center;
    padding: 56px 0;

    @media (max-width: 600px) {
      flex-direction: column;
      padding: 32px 0;
    }

    @media (max-width: 425px) {
      padding: 36px 0;
    }
  }

  &__text {
    width: 50%;
    text-align: right;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.3px;
    text-shadow: 0 1px 2px #184264;
    color: #fff;
    margin-right: 26.5px;
    @media (max-width: 768px) {
      font-size: 19px;
      margin-right: 24px;
    }

    @media (max-width: 600px) {
      width: auto;
      font-size: 20px;
      margin-right: 0;
      margin-bottom: 20px;
      text-align: center;
    }

    @media (max-width: 425px) {
      font-size: 15px;
      margin-bottom: 8px;
    }
  }

  &__logo {
    width: 50%;
    margin-left: 26.5px;

    @media (max-width: 768px) {
      width: auto;
      margin-left: 0;
    }

    @media (max-width: 600px) {
      margin-left: 0;
    }
  }

  &__logo-img {
    max-width: 100%;
    @media (max-width: 768px) {
      width: 308px;
    }
  }
}
