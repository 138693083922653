.contacts {
  margin: 154px 0 200px;
  @media (max-width: 768px) {
    margin-top: 100px;
  }
  @media (max-width: 600px) {
    margin: 70px 0 128px;
  }

  @media (max-width: 425px) {
    margin: 64px 0 80px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  &__map {
    @media (min-width: 601px) {
      width: 55%;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
      width: 65%;
    }
  }

  & #map {
    width: 100%;
    height: 280px;
    border-radius: 8px;
    overflow: hidden;

    @media (max-width: 600px) {
      height: 200px;
    }

    @media (max-width: 425px) {
      height: 160px;
    }
  }
}

.contacts-info {
  @media (min-width: 601px) and (max-width: 1024px) {
    width: 274px;
    margin-right: 30px;
  }

  @media (max-width: 600px) {
    margin-bottom: 32px;
  }

  & .heading {
    padding: 0 0 40px;
  }

  &__item {
    display: flex;
    margin-bottom: 16px;
    line-height: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    & .icon {
      margin-right: 16px;
      margin-top: 1px;
    }
  }
}
