.icon {
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
}

.i-location {
  background-image: url(../img/icons/i-location.svg);
}

.i-envelope {
  background-image: url(../img/icons/i-envelope.svg);
}

.i-phone {
  background-image: url(../img/icons/i-phone.svg);
}

.i-burger {
  width: 44px;
  height: 44px;
  background-image: url(../img/icons/i-burger.svg);
}
