.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 128px;
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
  @media (max-width: 425px) {
    height: 119px;
  }
}

.logo {
  display: flex;
  align-items: center;

  &__wrapper {
    display: inline-flex;
    align-items: center;
    max-width: 194px;
    height: 72px;
  }

  &__img {
    margin-top: -14px;
    max-width: 100%;
  }

  &__text {
    margin-left: 56px;
    font-size: 18px;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: #2d353b;
    @media (max-width: 1024px) {
      margin-left: 32px;
    }
    @media (max-width: 600px) {
      font-size: 16px;
      margin-left: 38px;
    }
    @media (max-width: 425px) {
      display: none;
    }
  }
}

.top-menu {
  @media (max-width: 768px) {
    display: none;
  }

  &__list {
    display: flex;
  }

  &__item {
    list-style: none;
    margin-right: 80px;
    @media (max-width: 1024px) {
      margin-right: 56px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.2px;
  }
}
